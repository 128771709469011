@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'iconsets/iconsets.scss';
@import 'theme';

html,
body {
  height: 100vh;
  width: 100vw;
  font-size: 11pt;
}

.composite-control {
  display: block;

  &.ng-invalid {
    border: 2px solid red;
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .ng-invalid {
    border: none;
    padding: 0;
    background: none;
  }

  input,
  input:focus {
    outline: none;
    box-shadow: none;
  }
}

fw-composite-input {
  .form-control {
    height: unset;
    -webkit-appearance: auto;
    appearance: auto;

    &:focus {
      box-shadow: none;
    }
  }

  button.btn:focus {
    box-shadow: none;
  }
}

fw-board-search-clerk {
  .tooltip {
    margin-top: -2rem;
  }

  .tooltip-inner {
    background-color: white;
    border-radius: 0.5rem;
    padding: 0 0 0.5rem 0;
    border: 0.1rem solid $color-primary-base;
  }

  .arrow {
    visibility: hidden;
  }

  .bs-tooltip-bottom {
    padding: 0;
  }
}

fw-schedule-layout {
  .as-split-gutter {
    background-color: $color-primary-dark !important;
  }
}

fw-distance-select {
  .ng-value {
    width: 100%;
  }
}

fw-view-selector {
  .ng-select.ng-select-single {
    >.ng-select-container {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
  }
}

.ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      height: 100%;
      border: 0;
      border-radius: 0;

      .ng-value-container {
        padding-top: 0;

        .ng-placeholder {
          position: unset;
          top: unset;
          padding: 0;
        }
      }
    }
  }
}

.dragging {
  z-index: 1000;
}

.unrestricted-tooltip .tooltip-inner {
  max-width: unset;
}

.ngb-tp {
  .ngb-tp-input-container {
    width: initial;

    .ngb-tp-input {
      padding: 0em;
    }
  }

  .ngb-tp-spacer {
    width: 2em !important;
  }
}

h6,
.h6 {
  line-height: 1.5;
}

.badge.bg-secondary {
  background-color: $color-secondary-darker !important;
}

.badge.bg-success {
  background-color: $color-success-greener !important;
}

.modal-header {
  .btn-close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }
}

.btn-close {
  color: $color-control-background;
  background: none;
  background-color: transparent;
  width: 0.9rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  box-shadow: none;
}

.btn-close:focus {
  box-shadow: none;
}

fw-edit-edd-model {
  .btn-close {
    width: 0.1rem;
  }

  .form-control {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

fw-area-selector {
  fw-composite-input {
    .btn.btn-link:hover {
      text-decoration: underline !important;
    }
  }
}

fw-time-input {
  .form-control-lg {
    height: calc(1.5em + 0.75rem + 2px);
    min-height: initial;
    font-size: 1rem;
  }
}